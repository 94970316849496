import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Grid, Paper, Stack, Typography } from "@mui/material"
import { GET_ROUND } from "../../queries"
import { Box } from "@mui/system"
import { green, orange, yellow, red, blue } from "../../theme/palette"

const RoundPage = ({ serverData }) => {
    let { round } = serverData.data;
    let { course, user, stats } = round;
    console.log(round);

    const headerSection = () => {
        return (
            <Box>
                <Typography variant="h2">{course.fullName}</Typography>
                <Typography variant="h4">{user.name}</Typography>
            </Box>
        )
    }

    const statsSection = () => {
        if (!stats) {
            return (<Box />)
        }

        const gridItem = (name: string, value: string) => {
            return (
                <Grid item xs={6}>
                    <Paper variant="outlined" square>
                        <Stack justifyContent={"center"} paddingTop={3} paddingBottom={3}>
                            <Typography variant="h3" textAlign={"center"}>
                                {value}
                            </Typography>
                            <Typography variant="h5" textAlign={"center"}>
                                {name}
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
            )
        }

        var scoreFromParString = `${stats.scoreFromPar}`;
        if (stats.scoreFromPar > 0) {
            scoreFromParString = `+${stats.scoreFromPar}`
        } else if (stats.scoreFromPar == 0) {
            scoreFromParString = `E`
        }

        return (
            <Grid container>
                {gridItem("Score", `${stats.score} (${scoreFromParString})`)}
                {gridItem("GIR", `${stats.greensInReg}`)}
                {gridItem("Putts", `${stats.putts}`)}
                {gridItem("Drops", `${stats.drops}`)}
            </Grid>
        )
    }

    const chartSection = () => {
        const valuePairs = [
            ["Eagles", stats.eagles, green],
            ["Birdies", stats.birdies, green],
            ["Pars", stats.pars, green],
            ["Bogeys", stats.bogies, blue],
            ["2 Bogeys", stats.doubleBogies, orange],
            ["3+ Bogeys", stats.triplePlusBogies, red],
        ].filter((e) => {
            return e[1] != null && e[1] > 0
        })

        var maxValue = 0
        for (const pair of valuePairs) {
            if (pair[1] > maxValue) {
                maxValue = pair[1];
            }
        }

        var bars = []
        for (const pair of valuePairs) {
            let width = pair[1] / maxValue * 8
            bars.push((
                <Grid container key={pair[0]} alignItems="center">
                    <Grid item xs={5} sm={3}>
                        <Typography variant="h6">
                            {pair[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="h6" fontWeight={"bold"}>
                            {pair[1]}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={pair[1] / maxValue * 6}
                        sm={pair[1] / maxValue * 8}
                        height={20}
                        sx={{ backgroundColor: pair[2], borderRadius: 8 }}
                    />
                </Grid>
            ))
        }

        return (
            <Paper variant="outlined">
                <Stack margin={3}>
                    {bars}
                </Stack>
            </Paper >
        )
    }

    return (
        <Layout>
            <Stack spacing={6}>
                {headerSection()}
                {statsSection()}
                {chartSection()}
            </Stack>
        </Layout>
    )
}

export const Head = ({ location, params, data, pageContext, serverData }) => {
    let id = location.pathname.replace("/rounds/", "")
    return (
        <SEO title="Round" imageUrl={`https://greens-scorecard.onrender.com/round_image/${id}.png`}></SEO>
    )
}

export async function getServerData({ params }) {
    try {
        const res = await fetch("https://api.greensgolf.app/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: GET_ROUND,
                variables: {
                    roundsID: params.id
                }
            })
        })

        if (!res.ok) {
            throw new Error(`Response failed`)
        }

        let json = await res.json()
        return {
            status: 200,
            props: json
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}

export default RoundPage;
